import SectionFoundation from "../../../components/LandingPage/AboutSection/SectionFoundation";
import SectionLisensi from "../../../components/LandingPage/AboutSection/SectionLisensi";
import SectionReview from "../../../components/LandingPage/AboutSection/SectionReview";
import SectionSejarah from "../../../components/LandingPage/AboutSection/SectionSejarah";
import SectionTentang from "../../../components/LandingPage/AboutSection/SectionTentang";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionApp from "../../../components/LandingPage/HomeSection/SectionApp";
import TabTitle from "../../../utils/TabTitle";

const AboutPage = () => {
    TabTitle("Tentang - Cleansheet");
    return (
        <>
        <SectionTentang />
        <SectionSejarah />
        <SectionFoundation />
        <SectionReview />
        <SectionLisensi />
        <SectionApp />
        <FloatingWhatsapp />
        </>
    );
}

export default AboutPage;