import React from "react";
import sedekahRombongan from "../../../assets/images/brands/sedekah-rombongan.png";
import merc from "../../../assets/images/brands/mer-c.png";
import kemenpora from "../../../assets/images/brands/kemenpora.webp";
import ristekdikti from "../../../assets/images/brands/ristekdikti.png";
import jica from "../../../assets/images/brands/jica.png";
import dompetDhuafa from "../../../assets/images/brands/dompet-dhuafa.png";
import sablonBogor from "../../../assets/images/brands/sablon-bogor.png";
import meccanism from "../../../assets/images/brands/meccanism.png";
import diBalikLayar from "../../../assets/images/brands/di-balik-layar.png";
import mandiriSyariah from "../../../assets/images/brands/mandiri-syariah.png";
import kataData from "../../../assets/images/brands/kata-data.png";
import crt from "../../../assets/images/brands/crt.png";
import oluCoffeeKitchen from "../../../assets/images/brands/olu-coffee-kitchen.png";
import tdaCommunity from "../../../assets/images/brands/tda-community.png";
import dailyCatering from "../../../assets/images/brands/daily-catering.png";
import masalal from "../../../assets/images/brands/masalal.png";
import aspherica from "../../../assets/images/brands/aspherica.png";
import bprDpTaspen from "../../../assets/images/brands/bpr-dp-taspen.png";
import ptSan from "../../../assets/images/brands/pt-san.png";
import tebarAqiqah from "../../../assets/images/brands/tebar-aqiqah.png";
import weddingDesignBogor from "../../../assets/images/brands/wedding-design-bogor.png";
import amura from "../../../assets/images/brands/amura.png";
import asiaPr from "../../../assets/images/brands/asia-pr.png";

const SectionPartnerKlienKami = () => {
  return (
    <section id="klien-kami" className="py-16">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="pb-8 flex flex-col gap-4">
          <div className="flex justify-start lg:justify-center sm:text-xl md:text-[22px] font-normal leading-[140%] tracking-wider">
            KLIEN KAMI
          </div>
          <div className="font-bold flex flex-wrap justify-start lg:justify-center text-2xl md:text-[32px] leading-[140%] montserrat whitespace-nowrap">
            Kami Sudah Melayani&nbsp;
            <span>
              <span className="text-primary">1000+</span>&nbsp;Pelanggan
            </span>
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-center gap-x-28 gap-y-16">
          <img
            src={sedekahRombongan}
            alt="sedekah-rombongan"
            className="h-16"
          />
          <img src={merc} alt="mer-c" className="h-16" />
          <img src={kemenpora} alt="kemenpora" className="h-16" />
          <img src={ristekdikti} alt="ristekdikti" className="h-16" />
          <img src={jica} alt="jica" className="h-16" />
          <img src={dompetDhuafa} alt="dompet-dhuafa" className="h-16" />
          <img src={sablonBogor} alt="sablon-bogor" className="h-16" />
          <img src={meccanism} alt="meccanism" className="h-16" />
          <img src={diBalikLayar} alt="di-balik-layar" className="h-16" />
          <img src={mandiriSyariah} alt="mandiri-syariah" className="h-16" />
          <img src={kataData} alt="kata-data" className="h-16" />
          <img src={crt} alt="crt" className="h-16" />
          <img
            src={oluCoffeeKitchen}
            alt="olu-coffee-kitchen"
            className="h-16"
          />
          <img src={tdaCommunity} alt="tda-community" className="h-16" />
          <img src={dailyCatering} alt="daily-catering" className="h-16" />
          <img src={masalal} alt="masalal" className="h-16" />
          <img src={aspherica} alt="aspherica" className="h-16" />
          <img src={bprDpTaspen} alt="bpr-dp-taspen" className="h-16" />
          <img src={ptSan} alt="pt-san" className="h-16" />
          <img src={tebarAqiqah} alt="tebar-aqiqah" className="h-16" />
          <img
            src={weddingDesignBogor}
            alt="wedding-design-bogor"
            className="h-16"
          />
          <img src={amura} alt="amura" className="h-16" />
          <img src={asiaPr} alt="asia-pr" className="h-16" />
        </div>
      </article>
    </section>
  );
};

export default SectionPartnerKlienKami;
