import sertif1 from "../../../assets/images/misc/sertif-1.png";
import sertif2 from "../../../assets/images/misc/sertif-2.png";

function SectionLisensi() {
  return (
    <section className="py-16 relative" id="lisensi">
      <article className="grid grid-cols-2 gap-12 max-w-container mx-auto px-6 md:px-4">
        <div className="col-span-2 lg:col-span-1 flex flex-col gap-5 self-center">
          <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
            <span className="underlined">Lisensi dan Sertifikasi</span>
          </h2>
          <div>
            <div>
              Cleansheet merupakan Startup Cleaning Service Profesional berbasis
              sociotechnopreneur yang memberdayakan mahasiswa/pelajar
              prasejahtera dan anak putus sekolah dengan menggunakan teknologi
              modern dan canggih untuk menyelesaikan berbagai macam permasalahan
              kebersihan.
            </div>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="col-span-1 flex justify-center">
            <img className="max-w-[200px]" src={sertif1} alt="" />
          </div>
          <div className="col-span-1 flex justify-center">
            <img className="max-w-[200px]" src={sertif2} alt="" />
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionLisensi;
