import {mdiHandHeart, mdiRepeatVariant} from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

const SectionDalamAngka = () => {
  return (
    <section id="cleansheet-dalam-angka" className="pt-16 pb-6 bg-primaryDark">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="flex justify-center">
          <div className="text-white montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em]">
            <span className="text-primaryBright">Clean</span>Sheet dalam Angka
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-40">
          <div className="flex flex-row gap-6">
            <div className="bg-white p-6 rounded-2xl">
              <Icon path={mdiHandHeart} size={1.66} className="text-primary" />
            </div>
            <div className="flex flex-col items-start">
              <div className="montserrat not-italic font-bold text-5xl leading-[120%] tracking-[0.005em] text-white">
                1175
              </div>
              <div className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-white">
                Konsumen Puas
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="bg-white p-6 rounded-2xl">
              <Icon
                path={mdiRepeatVariant}
                size={1.66}
                className="text-primary"
              />
            </div>
            <div className="flex flex-col items-start">
              <div className="montserrat not-italic font-bold text-5xl leading-[120%] tracking-[0.005em] text-white">
                23 %
              </div>
              <div className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-white">
                Repeat Order
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default SectionDalamAngka;
