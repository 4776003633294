import img from '../../../../assets/images/banner/poles-lantai.png';

function SectionProblems() {
    return (
        <section className="py-16" id="sectionProblems">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="center-web">
                        <img src={img} alt="" />
                    </div>
                    <div className='self-center'>
                        <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                            Apakah Anda Mengalami <span className="text-primary">Masalah</span> Berikut?
                        </h2>
                        <ul className='list-disc text-base text-default text-[22px] mt-4 px-6'>
                            <li>Lantai terkena tumpahan makanan atau minuman?</li>
                            <li>Lantai terkena debu atau goresan pasir?</li>
                            <li>Lantai terkena cipratan zat asam?</li>
                            <li>Lantai terkena kotoran binatang peliharaan?</li>
                            <li>Lantai terkena kotoran dan bercak alas kaki?</li>
                            <li>Atau lantai terkena kotoran lainnya?</li>
                        </ul>
                        <p className='text-base text-default text-[22px] mt-4'>Selamat! Anda berada di halaman yang tepat untuk mencari mitra jasa poles lantai profesional yang siap membersihkan kembali lantai Anda kembali seperti baru</p>
                    </div>
                </div>

            </article>
        </section>
    );
}

export default SectionProblems;