import React from "react";
import Button from "../../../Button";
import api from "../../../../api";
import {useEffect, useState} from "react";
import ModalAlert from "../../../ModalAlert";

function SectionPenyemprotanDisinfektan() {
  const [dataServices, setDataServices] = useState([]);
  const [modalOpen, setModalOpen] = useState({status: false, type: ""});
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });
  const getData = async () => {
    try {
      const {data} = await api.get("/v1/categories/7/services");
      setDataServices(data.data.items);
    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage: "Gagal Mendapatkan Data Layanan dari Server",
      });
      setModalOpen({status: true, type: "alert"});
    }
  };

  function kFormatter(rate) {
    return Math.abs(rate) > 999
      ? Math.sign(rate) * (Math.abs(rate) / 1000).toFixed(1) + "K"
      : Math.sign(rate) * Math.abs(rate);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ModalAlert
        isOpen={modalOpen.status && modalOpen.type === "alert"}
        alertTitle={modalAlert.alertTitle}
        alertMessage={modalAlert.alertMessage}
      />
      <section className="py-16 bg-primaryLight" id="sectionToilet">
        <article className="relative max-w-container mx-auto px-6 md:px-4">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9">
              {dataServices?.map((items, itemsIndex) => (
                <React.Fragment key={itemsIndex}>
                  {itemsIndex === 0 && (
                    <div className="text-[28px] mb-4 font-bold col-span-1 md:col-span-2 lg:col-span-4">
                      Daftar Harga
                    </div>
                  )}
                  {items.rate ? (
                    <div className="p-4 bg-transparent rounded-[32px] border border-primary bg-white flex flex-col">
                      <div className="text-base montserrat font-bold">
                        {items.name}
                      </div>
                      <div className="text-center mt-14 mb-14 text-[48px] montserrat text-dark font-bold">
                        <span className="font-bold text-[28px]">Rp</span>
                        {kFormatter(items.rate)}
                        <span className="font-normal text-[22px]">
                          /{items.unit}
                        </span>
                      </div>
                      <div className="mt-auto flex justify-center items-end">
                        <Button customStyle="center-web rounded-xl">
                          <div className="px-2 text-white font-bold">
                            Pesan Layanan
                          </div>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4 bg-transparent rounded-[32px] border border-primary bg-white flex flex-col">
                      <div className="text-base montserrat font-bold">
                        {items.name}
                      </div>
                      <div className="text-center mt-14 mb-14 text-[48px] montserrat text-dark font-bold">
                        Call Us
                      </div>
                      <div className="mt-auto flex justify-center items-end">
                        <Button customStyle="center-web rounded-xl">
                          <div className="px-2 text-white font-bold">
                            Pesan Layanan
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default SectionPenyemprotanDisinfektan;
