import {mdiArrowRight} from "@mdi/js";
import Icon from "@mdi/react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import advisor from "../../../assets/images/teams/arto-biantoro.png";
import ambassador from "../../../assets/images/teams/shahnaz-haque.png";
import ceo from "../../../assets/images/teams/dihqon-nadaamist.png";
import cofounder from "../../../assets/images/teams/sirojul-abidin.png";
import coo from "../../../assets/images/teams/faisal-budiman.png";
import cfo from "../../../assets/images/teams/wahyu-wulaningsih.png";
import React from "react";

function SectionTeam() {
  let team = [
    {
      photo: advisor,
      name: "Arto Biantoro",
      position: "Advisor",
    },
    {
      photo: ambassador,
      name: "Shahnaz Haque",
      position: "Brand Ambassador",
    },
    {
      photo: ceo,
      name: "Dihqon Nadaamist",
      position: "CEO",
    },
    {
      photo: cofounder,
      name: "Sirojul Abidin",
      position: "Co-Founder",
    },
    {
      photo: coo,
      name: "Faisal Budiman",
      position: "COO",
    },
    {
      photo: cfo,
      name: "Wahyu Wulaningsih",
      position: "CFO",
    },
  ];
  return (
    <section
      id="tim-kami"
      className="relative w-full border-b-[1px] py-16 border-b-bright overflow-hidden"
    >
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="h-[400px] absolute top-0 left-0 w-full bg-primaryDark -z-10"></div>
        <div>
          <div className="flex justify-start md:justify-center mt-[11px] text-[22px] font-normal leading-[140%] tracking-wider text-white">
            TIM KAMI
          </div>
          <div className="font-bold flex justify-start md:justify-center text-[32px] leading-[140%] tracking-wider montserrat text-white">
            Bertemu dengan tim hebat Kami
          </div>
        </div>
      </article>
      <article>
        <Swiper
          breakpoints={{
            320: {
              spaceBetween: 8,
              centeredSlides: true,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
              slidesPerView: 1.1,
            },
            640: {
              spaceBetween: 10,
              centeredSlides: false,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
              slidesPerView: 2.1,
            },
            1024: {
              spaceBetween: 10,
              centeredSlides: false,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
              slidesPerView: 3.1,
            },
            1280: {
              spaceBetween: 15,
              centeredSlides: false,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 100,
              slidesOffsetAfter: 100,
              slidesPerView: 5.1,
            },
            1920: {
              slidesOffsetBefore: 350,
              slidesOffsetAfter: 350,
              slidesPerView: 6.1,
            },
          }}
        >
          {team.map((item, index) => (
            <React.Fragment key={index}>
              <SwiperSlide>
                <div className="flex justify-center mt-[46px]">
                  <img
                    src={item.photo}
                    alt={item.name}
                    className="w-full 2xl:w-[275px]"
                  />
                </div>
                <div className="flex justify-center my-4 font-bold text-[22px] leading-[140%] tracking-wider">
                  {item.name}
                </div>
                <div className="flex justify-center font-normal text-default text-base leading-[140%] tracking-wider">
                  {item.position}
                </div>
              </SwiperSlide>
            </React.Fragment>
          ))}
        </Swiper>
      </article>
      <article className="max-w-container mx-auto px-6 md:px-4 flex justify-center mt-[66px]">
        <button className="flex font-normal text-base leading-[140%] tracking-wider text-primary">
          Lihat Semua Tim Kami
          <Icon path={mdiArrowRight} size={1} className="ml-[10px]" />
        </button>
      </article>
    </section>
  );
}

export default SectionTeam;
