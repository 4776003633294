import bgtop from "../../../../assets/images/textures/bg-top.png";
import Button from "../../../Button";

function SectionVideo() {
  return (
    <section className="relative py-16 bg-primaryLight" id="services">
      {/* <img className='-z-10 absolute top-0 left-0' src={bgtop} alt="" />
            <img className='-z-10 absolute bottom-0 right-0' src={bgbottom} alt="" /> */}
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 lg:col-span-1 self-center">
            <h1 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
              One Corp Many Hope
            </h1>
            <p className="mt-6 mb-6 text-base text-default">
              Jasa cleaning service kantor dengan paket Berlangganan yang tidak
              hanya menyelesaikan masalah kebersihan, kami juga membantu
              mengurangi masalah pengangguran, anak putus sekolah dan
              pengentasan kemiskinan. Jasa bersih kantor terpercaya dan
              terbersih di Indonesia
            </p>
            <Button customStyle="rounded-xl">
              <div className="px-2 text-white font-bold">
                Langganan One Corp Many Hope
              </div>
            </Button>
          </div>
          <div className="col-span-2 md:col-end-1 md:col-span-5 lg:col-span-1 flex justify-end">
            <div className="iframe-16-9-ratio rounded-xl">
              <iframe
                className="absolute inset-0 w-full h-full"
                src="https://www.youtube.com/embed/6eHCAD6ENiE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                allowFullScreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              />
            </div>
          </div>
        </div>
        <img className="-z-10 absolute top-0 left-0" src={bgtop} alt="" />
      </article>
    </section>
  );
}

export default SectionVideo;
