import React from "react";
import SectionSejarah from "../../../components/LandingPage/AboutSection/SectionSejarah";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionAbout from "../../../components/LandingPage/HomeSection/SectionAbout";
import SectionApp from "../../../components/LandingPage/HomeSection/SectionApp";
import SectionBanner from "../../../components/LandingPage/HomeSection/SectionBanner";
import SectionBlog from "../../../components/LandingPage/HomeSection/SectionBlog";
import SectionDampak from "../../../components/LandingPage/HomeSection/SectionDampak";
import SectionInfo from "../../../components/LandingPage/HomeSection/SectionInfo";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionLayanan from "../../../components/LandingPage/HomeSection/SectionLayanan";
import SectionMedia from "../../../components/LandingPage/HomeSection/SectionMedia";
import SectionPesan from "../../../components/LandingPage/HomeSection/SectionPesan";
import SectionSubscribe from "../../../components/LandingPage/HomeSection/SectionSubscribe";
import SectionTeam from "../../../components/LandingPage/HomeSection/SectionTeam";
import SectionTestimoni from "../../../components/LandingPage/HomeSection/SectionTestimoni";
import TabTitle from "../../../utils/TabTitle";

const HomePage = () => {
  TabTitle("Beranda - Cleansheet");
  return (
    <>
      <SectionBanner />
      <SectionInfo />
      <SectionSejarah
        background="bg-banner-texture bg-cover"
        displayParticle={false}
      />
      <SectionAbout />
      <SectionDampak />
      <SectionLayanan />
      <SectionKenapa />
      <SectionPesan />
      <SectionTeam />
      <SectionTestimoni />
      <SectionMedia />
      <SectionApp />
      <SectionBlog />
      <SectionSubscribe />
      <FloatingWhatsapp />
    </>
  );
};

export default HomePage;
