function SectionAbout() {
  return (
    <section className="w-full py-6 lg:py-16 relative" id="tentang-kami">
      <div className="md:max-w-container mx-auto px-6 md:px-4 grid grid-cols-1 lg:grid-cols-12 gap-4">
        <article className="col-span-1 lg:col-span-6 flex flex-col gap-5">
          <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
            <span className="underlined">Tentang Kami</span>
          </h2>
          <div className="flex flex-col gap-5">
            <p>
              Cleansheet merupakan Startup Cleaning Service Profesional berbasis
              sociotechnopreneur yang memberdayakan mahasiswa/pelajar
              prasejahtera dan anak putus sekolah dengan menggunakan teknologi
              modern dan canggih untuk menyelesaikan berbagai macam permasalahan
              kebersihan.
            </p>
            <p>
              Untuk saat ini Cleansheet melayani pembersihan di area Jakarta,
              Bogor, Depok, Tangerang, Tangerang Selatan dan Bekasi
              (Jabodetabek). Misi kami adalah untuk memberikan kesempatan bagi
              setiap individu menjadi lebih baik dari segi ekonomi, pendidikan,
              dan masa depan.
            </p>
          </div>
        </article>
        <aside className="col-span-1 lg:col-end-13 lg:col-span-5 flex justify-end">
          <div className="iframe-16-9-ratio rounded-xl">
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/6wDfuzmzNeM"
              title="Cleansheet Indonesia Profile Video"
              aria-label="Cleansheet Indonesia Profile Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
              allowFullScreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            />
          </div>
        </aside>
      </div>
    </section>
  );
}

export default SectionAbout;
