import hero from '../../../assets/images/misc/hero.png';

function SectionTentang() {
    return (
        <section className="pt-16 relative" id="tentang-kami">
            <article className="max-w-container mx-auto px-6 md:px-4 grid grid-cols-1 lg:grid-cols-2 gap-10">
                <div className="col-span-1 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                        <span className="underlined">Tentang Kami</span>
                    </h2>
                    <div className="pb-16">
                        <div>
                        Cleansheet merupakan Startup Cleaning Service Profesional berbasis
                        sociotechnopreneur yang memberdayakan mahasiswa/pelajar
                        prasejahtera dan anak putus sekolah dengan menggunakan teknologi
                        modern dan canggih untuk menyelesaikan berbagai macam permasalahan
                        kebersihan.
                        </div>
                        <div className="mt-5">
                        Untuk saat ini Cleansheet melayani pembersihan di area Jakarta,
                        Bogor, Depok, Tangerang, Tangerang Selatan dan Bekasi
                        (Jabodetabek). Misi kami adalah untuk memberikan kesempatan bagi
                        setiap individu menjadi lebih baik dari segi ekonomi, pendidikan,
                        dan masa depan.
                        </div>
                    </div>
                </div>
                <div className="col-span-1 flex justify-end">
                    <div className="relative max-w-[584px] max-h-[410px] lg:w-[584px]">
                        <img className="absolute bottom-0 w-full" src={hero} alt="" />
                    </div>
                </div>
            </article>
        </section>
    );
}

export default SectionTentang;