import React from "react";
import {Outlet} from "react-router-dom";
import FormHeader from "../components/FormPage/FormHeader";

const FormLayout = ({transactionCode}) => {
  return (
    <>
      <header id="header" className="top-0 z-50 sticky">
        <FormHeader transactionCode={transactionCode} />
      </header>
      <main id="main">
        <Outlet />
      </main>
    </>
  );
};

export default FormLayout;
