import {
  mdiArrowLeftCircleOutline,
  mdiArrowRightCircleOutline,
  mdiArrowRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import testimoniBanner from "../../../assets/images/misc/testimoni.png";
import testimoni1 from "../../../assets/images/testimoni/testimoni-1.png";
import testimoni2 from "../../../assets/images/testimoni/testimoni-2.png";
import testimoni3 from "../../../assets/images/testimoni/testimoni-3.png";
import quots from "../../../assets/images/icons/quots.png";
import vectror3 from "../../../assets/images/textures/vector3.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import React from "react";

function SectionTestimoni() {
  let testimoni = [
    {
      images: testimoni1,
      title: "Jasa cuci springbed dan sofa paling oke",
      description:
        "Mantap nih, sofa sedekil apapun jadi kayak baru lagi. Jasa cuci springbed dan sofa paling oke",
      name: "Babe Cabita",
      position: "Artist",
    },
    {
      images: testimoni2,
      title: "Jasa membersihkan rumah recomended",
      description:
        "Pelayanannya Cleansheet, cepat, bersih, rapih, dan aman. Jasa membersihkan rumah recomended",
      name: "Soraya Haque",
      position: "Artist",
    },
    {
      images: testimoni3,
      title: "Jasa bersih rumah terpercaya dan mempunyai pelayanan dari hati.",
      description:
        "Segala permasalahan rumah pokoknya menjadi beres, sampai halaman rumah juga loh. 1 panggilan anda berarti membantu 1 anak putus sekolah. Jasa bersih rumah terpercaya dan mempunyai pelayanan dari hati.",
      name: "Shahnaz Haque",
      position: "Artist",
    },
  ];
  return (
    <section id="testimoni" className="py-16 relative">
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="flex">
          <div className="font-bold flex-auto text-[32px] leading-[140%] tracking-wider montserrat mb-6">
            Testimoni Klien CleanSheet
          </div>
          <div className="hidden md:flex lg:justify-between gap-[30px] mb-6">
            <button className="flex justify-center self-center disabled:opacity-50 testimoni-swiper-button-prev">
              <Icon
                path={mdiArrowLeftCircleOutline}
                size={1.5}
                className="text-primary"
              />
            </button>
            <button className="flex justify-center self-center disabled:opacity-50 testimoni-swiper-button-next">
              <Icon
                path={mdiArrowRightCircleOutline}
                size={1.5}
                className="text-primary"
              />
            </button>
          </div>
        </div>
        <div className="grid col-span-1 lg:grid-cols-2 gap-4">
          <div className="order-last lg:order-first col-span-1 relative flex justify-center 2xl:justify-start items-end">
            <div>
              <img src={testimoniBanner} alt="Testimoni" className="w-full" />
            </div>
          </div>
          <div className="order-first relative lg:order-2 col-span-1 py-2 min-w-0">
            <div className="absolute -left-20 top-0 bg-primaryDark rounded-full w-[72px] h-[72px] hidden lg:flex justify-center z-10">
              <img src={quots} alt="quote" className="self-center flex h-6" />
            </div>
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={30}
              centeredSlides={true}
              navigation={{
                nextEl: ".testimoni-swiper-button-next",
                prevEl: ".testimoni-swiper-button-prev",
              }}
              pagination={{
                el: ".testimoni-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '"></span>';
                },
              }}
              modules={[Pagination, Navigation]}
            >
              {testimoni.map((item, index) => (
                <React.Fragment key={index}>
                  <SwiperSlide>
                    <div className="p-6 h-full">
                      <div className="cardTestimoni px-8 py-6 w-full h-full flex flex-col">
                        <div className="text-dark flex font-bold sm:text-[20px] leading-[140%] tracking-wider">
                          {item.title}
                        </div>
                        <div className="mt-2 text-dark flex font-normal text-base leading-[140%] tracking-wider">
                          {item.description}
                        </div>
                        <div className="flex mt-auto">
                          <img
                            src={item.images}
                            alt={item.name}
                            className="rounded-full border-2 border-white w-12 h-12"
                          />
                          <div className="ml-4 text-dark flex font-bold text-[20px] leading-[140%] tracking-wider self-center">
                            {item.name} - {item.position}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              ))}
            </Swiper>
            <div className="mt-[49px] flex flex-col md:flex-row gap-y-4 w-full">
              <div className="flex-0 md:flex-auto">
                <div className="testimoni-pagination whitespace-nowrap"></div>
              </div>
              <div>
                <button className="flex font-normal text-base leading-[140%] tracking-wider text-primary">
                  Testimoni lengkap klien kami
                  <Icon path={mdiArrowRight} size={1} className="ml-[10px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          src={vectror3}
          alt=""
          className=" h-[389px] absolute -bottom-28 right-0 -z-10 hidden xl:block"
        />
      </article>
      <div className="hidden lg:block BGleft bottom-0 left-0 mt-1 -z-10"></div>
    </section>
  );
}

export default SectionTestimoni;
