import model from "../../../assets/images/misc/model.png";
import guarantee from "../../../assets/images/icons/guarantee.png";
import plant from "../../../assets/images/icons/plant.png";
import time from "../../../assets/images/icons/time.png";
import vaccine from "../../../assets/images/icons/vaccine.png";
import price from "../../../assets/images/icons/price.png";
import doctor from "../../../assets/images/icons/doctor.png";
import team from "../../../assets/images/icons/team.png";
import rating from "../../../assets/images/icons/rating.png";

function SectionKenapa({background = "bg-light"}) {
  return (
    <section
      className={"py-[68px] relative " + background}
      id="partner-terbaik"
    >
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="flex mt-[11px] text-lg md:text-[22px] font-normal leading-[140%] tracking-wider">
          PARTNER TERBAIK
        </div>
        <div className="mt-[16px] font-bold text-2xl flex  md:text-[32px] leading-[140%] tracking-wider montserrat">
          Kenapa Harus&nbsp;
          <span className="text-primary ">Clean</span>Sheet
        </div>
        <div className="grid grid-cols-3 mt-[44px]">
          <div className="col-span-3 2xl:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-y-4 lg:pr-24">
            <div className="flex">
              <img src={guarantee} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Garansi
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami memberikan garansi disetiap pekerjaan yang sudah kami
                  lakukan.
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={plant} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Ramah Lingkungan
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami selalu menggunakan bahan-bahan yang ramah lingkungan
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={time} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Cepat
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami selalu memberikan layanan dengan waktu seefektif dan
                  seefisien
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={vaccine} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Tim Sudah Divaksin
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Tim kami sudah melalui proses vaksinasi sebelum melakukan
                  setiap pekerjaan
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={price} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Harga Terjangkau
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami selalu memberikan harga penawaran terbaik untuk setiap
                  jasa
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={doctor} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Pakai APD Lengkap
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami selalu memakai alat pelindung diri yang lengkap dalam
                  setiap pekerjaan
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={team} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Tim Ahli dan Profesional
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami selalu memberikan layanan dengan waktu pengerjaan
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={rating} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Ribuan Klien
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Sudah ribuan klien kami tangani di seluruh Indonesia
                </div>
              </div>
            </div>
          </div>
          <div className="hidden 2xl:block col-span-3 xl:col-span-1 relative">
            <img src={model} alt="" className="h-[500px] z-10" />
          </div>
        </div>
      </article>
      <div className="hidden 2xl:block BGright bottom-0 right-0 mt-1"></div>
    </section>
  );
}

export default SectionKenapa;
