import {useEffect, useState} from "react";
import api from "../../../../api";
import Button from "../../../Button";
import ModalAlert from "../../../ModalAlert";

function SectionLayanan() {
  const [dataServices, setDataServices] = useState([]);
  const [modalOpen, setModalOpen] = useState({status: false, type: ""});
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });
  const getData = async () => {
    try {
      const {data} = await api.get("/v1/categories/2/services");
      setDataServices(data.data.items);
    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage: "Gagal Mendapatkan Data Layanan dari Server",
      });
      setModalOpen({status: true, type: "alert"});
    }
  };

  function kFormatter(rate) {
    return Math.abs(rate) > 999
      ? Math.sign(rate) * (Math.abs(rate) / 1000).toFixed(1) + "K"
      : Math.sign(rate) * Math.abs(rate);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ModalAlert
        isOpen={modalOpen.status && modalOpen.type === "alert"}
        alertTitle={modalAlert.alertTitle}
        alertMessage={modalAlert.alertMessage}
      />
      <section className="py-16" id="sectionLayanan">
        <article className="relative max-w-container mx-auto px-6 md:px-4">
          <div className="grid grid-cols-4 gap-9">
            <div className="col-span-3 self-center">
              <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat mb-4">
                Layanan <span className="text-primary">Deep Cleanin</span>{" "}
                Profesional
              </h2>
              <p className="text-default text-[22px]">
                Buat Anda yang saat ini sedang mencari jasa layanan untuk
                membersihkan rumah/kantor super kotor setelah renovasi, pasca
                banjir, dan telah lama tidak dihuni hingga bersih seperti baru.
              </p>
            </div>
            <div className="col-span-4 lg:col-span-1">
              {dataServices?.map((items) => (
                <div
                  key={items.id}
                  className="p-4 bg-transparent rounded-[32px] border border-primary"
                >
                  <div className="text-base montserrat font-bold">
                    {items.name}
                  </div>
                  <div className="text-center mt-10 mb-10 text-[48px] montserrat text-dark font-bold">
                    <span className="font-bold text-[28px]">Rp</span>
                    {kFormatter(items.rate)}
                    <span className="font-normal text-[22px]">
                      /{items.unit}
                    </span>
                  </div>
                  <div className="mt-auto flex justify-center">
                    <Button customStyle="center-web rounded-xl">
                      <div className="px-2 text-white font-bold">
                        Pesan Layanan
                      </div>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default SectionLayanan;
