import {mdiChevronDown, mdiChevronUp} from "@mdi/js";
import Icon from "@mdi/react";
import {Menu, Transition} from "@headlessui/react";
import {Fragment} from "react";

function Dropdown({label, custom, aditional = "", dropdownIcon, ...props}) {
  let cus = custom;
  if (custom === null) {
    cus = "right-0 w-48";
  }

  return (
    <Menu as="div" className="relative">
      {({open}) => (
        <>
          <div>
            <Menu.Button
              className={
                aditional +
                (open
                  ? " bg-white ring-bright"
                  : " bg-neutralLight ring-neutralBright") +
                " outline-none border-none flex flex-row items-center ring-1 justify-between py-[6px] px-2"
              }
            >
              <span className="ml-3 small text-default">{label}</span>
              <span>
                {open ? (
                  <div className="flex items-center pr-1.5 text-primary">
                    {dropdownIcon || <Icon path={mdiChevronUp} size={1} />}
                  </div>
                ) : (
                  <div className="flex items-center pr-1.5 text-default">
                    {dropdownIcon || <Icon path={mdiChevronDown} size={1} />}
                  </div>
                )}
              </span>
            </Menu.Button>
          </div>
          <Transition as={Fragment}>
            <Menu.Items
              className={cus + " absolute mt-2 outline-none border-none z-10"}
            >
              {props.children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default Dropdown;
