import {mdiFacebook, mdiInstagram, mdiYoutube} from "@mdi/js";
import en from "../../assets/images/icons/en.png";
import {React} from "react";
import {ReactComponent as Phone} from "../../assets/vectors/icons/phone-icon.svg";
import {ReactComponent as Envelope} from "../../assets/vectors/icons/envelope-icon.svg";
import {ReactComponent as MapMarker} from "../../assets/vectors/icons/map-marker-icon.svg";
import Icon from "@mdi/react";

function Header() {
  return (
    <div
      className="w-full h-9 hidden md:block border-b border-neutral bg-white"
      aria-label="top-navbar"
    >
      <div className="grid grid-cols-12 md:max-w-container mx-auto">
        <ul className="col-span-6 flex flex-row">
          <li>
            <a
              href="https://wa.me/6281297984971"
              className="border-l border-neutral px-4 py-2 flex flex-row gap-1 h-9 items-center"
              title="WhatsApp"
            >
              <div>
                <Phone className="text-primary" />
              </div>
              <span className="ml-1 text-default text-xs leading-[140%] tracking-wider self-center">
                081297984971
              </span>
            </a>
          </li>
          <li>
            <a
              href="mailto:cs@cleansheet.id"
              className="border-x border-neutral px-4 py-2 flex flex-row gap-1 h-9 items-center"
              title="Email"
            >
              <div>
                <Envelope className="text-primary" />
              </div>
              <span className="ml-1 text-default text-xs leading-[140%] tracking-wider self-center">
                cs@cleansheet.id
              </span>
            </a>
          </li>
          <li>
            <a
              className="border-r border-neutral px-4 py-2 flex flex-row gap-1 h-9 items-center"
              href="https://goo.gl/maps/96DJaKdWRYMmSMYi9"
              title="Google Maps"
            >
              <div>
                <MapMarker className="text-primary" />
              </div>
              <span className="ml-1 text-default text-xs leading-[140%] tracking-wider whitespace-nowrap self-center">
                Kota Bogor
              </span>
            </a>
          </li>
        </ul>
        <ul className="col-end-13 col-span-4 flex flex-row justify-end">
          <li>
            <a
              href="https://facebook.com/cleansheetindonesia/"
              className="border-l-[1px] border-neutral px-4 py-2 flex h-9 justify-center"
              title="Facebook"
            >
              <Icon path={mdiFacebook} size={0.8} className="text-primary" />
            </a>
          </li>
          <li>
            <a
              className="border-x-[1px] border-neutral px-4 py-2 flex h-9 justify-center"
              href="https://www.youtube.com/channel/UCEQRB6oBhlbNldk0YZciaSA"
              title="Youtube"
            >
              <Icon path={mdiYoutube} size={0.8} className="text-primary" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/cleansheet_id/"
              className="border-r-[1px] border-neutral px-4 py-2 flex h-9 justify-center"
              title="Instagram"
            >
              <Icon path={mdiInstagram} size={0.8} className="text-primary" />
            </a>
          </li>
          <li>
            <button className="border-r-[1px] border-neutral px-4 py-2 flex h-9 justify-center">
              <img src={en} alt="English" className="h-full" />
              <span className="ml-1 text-dark text-xs leading-[140%] tracking-wider self-center !font-bold">
                EN
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
