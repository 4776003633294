import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionHydroCleaning from "../../../components/LandingPage/ServicesSection/HydroCleaning/SectionHydroCleaning";
import SectionProblems from "../../../components/LandingPage/ServicesSection/HydroCleaning/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/HydroCleaning/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/HydroCleaning/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const HydroCleaningPage = () => {
    TabTitle("Layanan Hydro Cleaning Profesional - Cleansheet");
    return (
        <>
            <SectionVideo />
            <SectionProblems />
            <SectionHydroCleaning />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'Hydro Vaccum'} />
            <FloatingWhatsapp />
        </>
    );
}

export default HydroCleaningPage;