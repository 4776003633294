import {
  mdiThumbUpOutline,
  mdiArrowLeftCircleOutline,
  mdiArrowRightCircleOutline,
  mdiArrowRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Navigation, Pagination} from "swiper";
import {Link, NavLink} from "react-router-dom";
import Button from "../../Button";
import generalCleaning from "../../../assets/images/banner/general-cleaning-banner.webp";
import deepCleaning from "../../../assets/images/banner/deep-cleaning-banner.webp";
import toiletCleaning from "../../../assets/images/banner/toilet-cleaning-banner.webp";
import polesLantai from "../../../assets/images/banner/poles-lantai-banner.webp";
import furnitureCleaning from "../../../assets/images/banner/cuci-banner.webp";
import hydroVaccum from "../../../assets/images/banner/hydro-vaccum-banner.webp";
import penyemprotanDisinfektan from "../../../assets/images/banner/fogging-disinfektan-banner.webp";
import oneHomeOneHope from "../../../assets/images/banner/subscription-banner.webp";
import oneCorpManyHope from "../../../assets/images/banner/outsource-cleaning-service-banner.webp";
import React from "react";

function SectionLayanan() {
  let layanan = [
    {
      image: generalCleaning,
      title: "General Cleaning",
      description:
        "Layanan jasa pembersihan rumah & apartemen profesional yang meliputi pembersihan debu (dusting), pembersihan lantai, pembersihan langit-langit, hingga pembersihan seluruh permukaan furniture dalam ruangan",
      link: "/services/general-cleaning",
    },
    {
      image: deepCleaning,
      title: "Deep Cleaning",
      description:
        "Layanan jasa pembersihan secara mendetail spesial untuk ruangan / rumah pasca renovasi, pasca banjir, dan telah lama ditinggalkan. Pembersihan meliputi pembersihan seluruh lantai, dinding, langit-langit, kaca, dan seluruh permukaan furniture",
      link: "/services/deep-cleaning",
    },
    {
      image: toiletCleaning,
      title: "Toilet Cleaning",
      description:
        "Layanan jasa membersihkan kerak membandel, bakteri, hingga kuman akan hilang dari kamar mandi",
      link: "/services/toilet-cleaning",
    },
    {
      image: polesLantai,
      title: "Poles Lantai",
      description:
        "Layanan jasa poles lantai, dinding, dan permukaan meja yang berbahan dasar keramik, marmer, granit, teraso, dan parkit hingga terlihat jauh lebih bersih dan mengilap",
      link: "/services/poles-lantai",
    },
    {
      image: furnitureCleaning,
      title: "Furniture Cleaning",
      description:
        "Layanan jasa laundry yang diperuntukkan kasur, sofa, karpet, dan kursi guna mengangkat noda, debu dan tungau secara maksimal",
      link: "/services/furniture-cleaning",
    },
    {
      image: hydroVaccum,
      title: "Hydro Vaccum",
      description:
        "Layanan sedot tungau & debu yang diperuntukkan untuk kasur, sofa, karpet dan gorden",
      link: "/services/hydro-vaccum",
    },
    {
      image: penyemprotanDisinfektan,
      title: "Penyemprotan Disinfektan",
      description:
        "Layanan jasa penyemprotan dan sterilisasi rumah/kantor dengan menggunakan cairan disinfektan tersertifikasi kemenkes yang aman bagi keluarga dan orang-orang tersayang untuk membunuh kuman, bakteri dan virus",
      link: "/services/penyemprotan-disinfektan",
    },
    {
      image: oneHomeOneHope,
      title: "One Home One Hope",
      description:
        'Program "One Home One Hope" yaitu setiap satu rumah yang dibersihkan oleh Cleansheet, dapat memberikan harapan bagi satu anak muda untuk melanjutkan pendidikannya. Kami mengajak sobat Cleansheet untuk berkontribusi lewat paket berlangganan bulanan dari Cleansheet',
      link: "/services/one-home-one-hope",
    },
    {
      image: oneCorpManyHope,
      title: "One Corp Many Hope",
      description:
        'Dalam program "One Corp Many Hope" yaitu setiap satu kantor yang dibersihkan oleh Cleansheet, dapat memberikan harapan bagi banyak anak muda untuk melanjutkan pendidikannya. Kami mengajak Anda untuk berkontribusi lewat Outsourcing Cleaning Service Cleansheet.',
      link: "/services/one-corp-many-hope",
    },
  ];
  return (
    <section
      className="relative overflow-hidden py-16 bg-service-texture bg-cover"
      id="layanan-kami"
    >
      <article className="md:max-w-container mx-auto px-6 md:px-4">
        <div className="flex flex-col items-start md:items-center w-full">
          <Icon path={mdiThumbUpOutline} size={1.5} className="text-primary" />
          <div className="flex mt-[11px] lg:text-[22px] font-normal leading-[140%] tracking-wider">
            LAYANAN KAMI
          </div>
        </div>
        <div className="flex flex-col justify-start md:justify-center">
          <div className="font-bold flex flex-row flex-wrap justify-start md:justify-center text-2xl lg:text-[32px] leading-[140%] montserrat">
            Program layanan yang ditawarkan
            <span>
              <span className="text-primary">Clean</span>Sheet
            </span>
          </div>
        </div>
        <div className="flex justify-start md:justify-center mt-[19px]">
          <span className="w-[560px] text-left md:text-center text-default text-base leading-[140%] tracking-wider">
            Prioritas kami adalah meyani Anda dengan sepenuh hari. Jangan lupa
            klik tombol subscribe dan share
          </span>{" "}
        </div>
      </article>
      <aside className="mt-[46px] min-w-0 md:max-w-container mx-auto px-0 sm:px-4 grid grid-cols-1 md:grid-cols-12">
        <button
          className="hidden md:flex justify-start col-span-1 layanan-swiper-button-prev disabled:opacity-50"
          title="Navigate Previous Slide"
          aria-label="Navigate Previous Service Slide"
        >
          <Icon
            path={mdiArrowLeftCircleOutline}
            size={2}
            className="flex justify-center self-center text-white"
          />
        </button>
        <div className="col-span-1 md:col-span-10">
          <div className="w-full md:max-w-[1024px]">
            <Swiper
              modules={[Navigation, Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  centeredSlides: true,
                },
                640: {
                  slidesPerView: 2,
                  centeredSlides: false,
                },
                1024: {
                  slidesPerView: 3,
                  centeredSlides: false,
                },
              }}
              spaceBetween={10}
              pagination={{
                el: ".layanan-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '"></span>';
                },
              }}
              navigation={{
                nextEl: ".layanan-swiper-button-next",
                prevEl: ".layanan-swiper-button-prev",
              }}
              loop={false}
            >
              {layanan.map((item, index) => (
                <React.Fragment key={index}>
                  <SwiperSlide>
                    <div className="bg-primaryDark rounded-[32px] p-6 h-full flex flex-col gap-4">
                      <div className="w-full h-[182.1px] rounded-2xl overflow-hidden">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="object-cover"
                        />
                      </div>
                      <div className="text-primaryLight montserrat not-italic font-bold text-2xl leading-[140%] tracking-[0.005em]">
                        {item.title}
                      </div>
                      <div className="text-white not-italic font-normal text-base leading-[140%] tracking-[0.005em]">
                        {item.description}
                      </div>
                      <div className="mt-auto">
                        <NavLink to={item.link}>
                          <Button customStyle=" rounded-full">
                            <div className="px-5 py-1 text-white">
                              Lihat Detail
                            </div>
                          </Button>
                        </NavLink>
                      </div>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              ))}
            </Swiper>
          </div>
        </div>
        <button
          className="hidden col-span-1 md:flex justify-end layanan-swiper-button-next disabled:opacity-50"
          title="Navigate Next Slide"
          aria-label="Navigate Next Service Slide"
        >
          <Icon
            path={mdiArrowRightCircleOutline}
            size={2}
            className="self-center text-white"
          />
        </button>
      </aside>
      <div
        className="mt-[58px] md:max-w-container mx-auto flex flex-col lg:flex-row justify-center lg:justify-between px-6 md:px-4"
        aria-label="service-navigation"
      >
        <div className="layanan-pagination block whitespace-nowrap"></div>
        <div className="mt-2 lg:mt-0">
          {" "}
          <Link
            to="/services"
            className="flex font-normal text-base leading-[140%] tracking-wider text-primaryDark"
          >
            <div>Lihat Semua Program dan Layanan</div>
            <div>
              <Icon path={mdiArrowRight} size={1} className="ml-[10px]" />
            </div>
          </Link>
        </div>
      </div>
      <div className="absolute h-1/2 bg-bright w-full bottom-0 -z-10"></div>
    </section>
  );
}

export default SectionLayanan;
