import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useLayoutEffect, useState} from "react";
import LandingPageLayout from "./layouts/LandingPageLayout";
import HomePage from "./pages/LandingPage/HomePage";
import HowToOrderPage from "./pages/LandingPage/HowToOrderPage";
import AboutPage from "./pages/LandingPage/AboutPage";
import ServicesPage from "./pages/LandingPage/ServicesPage";
import GeneralCleaningPage from "./pages/LandingPage/ServicesPage/GeneralCleaningPage";
import DeepCleaningPage from "./pages/LandingPage/ServicesPage/DeepCleaningPage";
import ToiletCleaningPage from "./pages/LandingPage/ServicesPage/ToiletCleaningPage";
import FurnitureCleaningPage from "./pages/LandingPage/ServicesPage/FurnitureCleaningPage";
import HydroCleaningPage from "./pages/LandingPage/ServicesPage/HydroCleaningPage";
import OneCorpPage from "./pages/LandingPage/ServicesPage/OneCorpPage";
import OneHomePage from "./pages/LandingPage/ServicesPage/OneHomePage";
import PenyemprotanDisinfektanPage from "./pages/LandingPage/ServicesPage/PenyemprotanDisinfektanPage";
import PolesLantaiPage from "./pages/LandingPage/ServicesPage/PolesLantaiPage";
import PartnerPage from "./pages/LandingPage/PartnerPage";
import BlogPage from "./pages/LandingPage/BlogPage";
import FormLayout from "./layouts/FormLayout";
import FormPesanPage from "./pages/FormPesan";
import FormDetailPage from "./pages/FormPesan/FormDetailPage";
import FormBayarPage from "./pages/FormPesan/FormBayarPage";
import JoinUsPage from "./pages/LandingPage/JoinUsPage";
import Rangers from "./pages/LandingPage/JoinUsPage/Rangers";
import Heroes from "./pages/LandingPage/JoinUsPage/Heroes";
import FormProgressPage from "./pages/FormPesan/FormProgressPage";

function App() {
  const [transactionCode, setTransactionCode] = useState("");

  return (
    <Wrapper>
      <Routes>
        <Route path={"/"} element={<LandingPageLayout />}>
          <Route index element={<HomePage />} />
          <Route path={"/about"} element={<AboutPage />} />
          <Route path={"/services"}>
            <Route index element={<ServicesPage />} />
            <Route
              path={"/services/general-cleaning"}
              element={<GeneralCleaningPage />}
            />
            <Route
              path={"/services/deep-cleaning"}
              element={<DeepCleaningPage />}
            />
            <Route
              path={"/services/toilet-cleaning"}
              element={<ToiletCleaningPage />}
            />
            <Route
              path={"/services/furniture-cleaning"}
              element={<FurnitureCleaningPage />}
            />
            <Route
              path={"/services/hydro-vaccum"}
              element={<HydroCleaningPage />}
            />
            <Route
              path={"/services/poles-lantai"}
              element={<PolesLantaiPage />}
            />
            <Route
              path={"/services/penyemprotan-disinfektan"}
              element={<PenyemprotanDisinfektanPage />}
            />
            <Route
              path={"/services/one-home-one-hope"}
              element={<OneHomePage />}
            />
            <Route
              path={"/services/one-corp-many-hope"}
              element={<OneCorpPage />}
            />
          </Route>
          <Route path={"/how-to-order"} element={<HowToOrderPage />} />
          <Route path={"/client"} element={<PartnerPage />} />
          <Route path={"/join-us"}>
            <Route index element={<JoinUsPage />} />
            <Route path={"/join-us/rangers"} element={<Rangers />} />
            <Route path={"/join-us/heroes"} element={<Heroes />} />
          </Route>
          <Route path={"/blog"} element={<BlogPage />} />
          <Route path={"/*"} element={<Navigate to="/" replace />} />
        </Route>
        <Route
          path={"/form"}
          element={<FormLayout transactionCode={transactionCode} />}
        >
          <Route index element={<FormPesanPage />} />
          <Route path={"/form/confirmation"} element={<FormDetailPage />} />
          <Route
            path={"/form/payment"}
            element={
              <FormBayarPage setTransactionCodeValue={setTransactionCode} />
            }
          />
          <Route path={"/form/*"} element={<Navigate to="/form" replace />} />
        </Route>
      </Routes>
    </Wrapper>
  );
}

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default App;
