import ellips from "../../../assets/images/textures/left-ellips.png";

function SectionSejarah({
  background = "bg-primaryLight",
  displayParticle = true,
}) {
  return (
    <section className={`${background} relative`} id="sejarah">
      {displayParticle && (
        <img
          src={ellips}
          alt=""
          className="w-[205.8px] h-[218.94] absolute left-0 bottom-0 -z-9"
        />
      )}
      <article className="max-w-container mx-auto px-6 md:px-4 py-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-20">
          <div className="col-span-1 flex justify-start">
            <div className="iframe-16-9-ratio rounded-xl">
              <iframe
                className="absolute inset-0 w-full h-full"
                src="https://www.youtube.com/embed/RKfHLCq4lZg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-5 self-center">
            <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
              <span className="underlined">Sejarah Kami</span>
            </h2>
            <div className="mb-6">
              Bermula dari pengalaman pribadinya menjadi mahasiswa penerima
              beasiswa Bidikmisi di IPB & kesulitan mencari penghasilan tambahan
              di sela-sela kesibukannya sehari-hari, Dihqon juga ingin membantu
              sesama mahasiswa Bidikmisi yang mengalami permasalahan yang sama.
              Maka Dihqon menciptakan usaha “Cleansheet”.
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionSejarah;
