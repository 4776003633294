import React from "react";
import Button from "../../../Button";
import {useEffect, useState} from "react";

function SectionLayanan() {
  return (
    <>
      <section className="py-16 bg-white" id="sectionToilet">
        {/* <img className='-z-10 absolute top-0 right-0' src={bgtop} alt="" />
            <img className='-z-10 absolute bottom-0 left-0' src={bgbottom} alt="" />
 */}
        <article className="relative max-w-container mx-auto px-6 md:px-4">
          <div className="grid grid-cols-4 gap-9">
            <div className="col-span-3 self-center">
              <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                Layanan{" "}
                <span className="text-primary">Cleaning Service Kantor</span>{" "}
                Profesional
              </h2>
              <p className="text-base text-default text-[22px] mt-4 mb-10">
                Buat Anda yang saat ini sedang mencari jasa layanan untuk
                membersihkan kantor super kotor setelah renovasi, pasca banjir,
                dan telah lama tidak dihuni hingga bersih seperti baru.
              </p>
            </div>
            <div className="col-span-4 lg:col-span-1">
              <div className="p-4 bg-transparent rounded-[32px] border border-primary">
                <div className="text-base montserrat font-bold">
                  One Corp Many Hope
                </div>
                <div className="text-center mt-10 mb-10 text-[48px] montserrat text-dark font-bold">
                  Call Us
                </div>
                <div className="mt-auto flex justify-center">
                  <Button customStyle="center-web rounded-xl">
                    <div className="px-2 text-white font-bold">
                      Pesan Layanan
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default SectionLayanan;
