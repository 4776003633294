import {React} from "react";

function SectionDeepCleaning() {
    return (
        <section className="py-16 bg-primaryLight" id="sectionDeep">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                    Apa Itu <span className="text-primary">Deep Cleanin?</span>
                </h2>
                <p className='text-base text-default text-[22px] mt-4 mb-10'>Ini cara kami membersihkan rumah/kantor super kotor setelah renovasi, pasca banjir, dan  telah lama tidak dihuni hingga bersih seperti baru. Pembersihan biasa tidak dapat membersihkan sisa-sisa noda debu tebal pasca renovasi yang menempel pada lantai, jendela, dan furniture, terlebih lumpur yang menempel pasca banjir sangat sulit untuk dibersihkan. Cleansheet hadir untuk memberikan pembersihan maksimal tanpa noda pada rumah sobat Cleansheet.</p>
            </article>
        </section>

    );
}

export default SectionDeepCleaning;