import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionPolesLantai from "../../../components/LandingPage/ServicesSection/PolesLantai/SectionPolesLantai";
import SectionProblems from "../../../components/LandingPage/ServicesSection/PolesLantai/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/PolesLantai/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/PolesLantai/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const PolesLantaiPage = () => {
    TabTitle("Layanan Poles Lantai Profesional - Cleansheet");
    return (
        <>
            <SectionVideo />
            <SectionProblems />
            <SectionPolesLantai />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'Poles Lantai'} />
            <FloatingWhatsapp />
        </>
    );
}

export default PolesLantaiPage;