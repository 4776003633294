import testimoni1 from '../../../../assets/images/testimoni/testimoni-1.png';
import testimoni2 from '../../../../assets/images/testimoni/testimoni-2.png';
import testimoni3 from '../../../../assets/images/testimoni/testimoni-3.png';

function SectionTestimoni() {
    return (
        <section className="relative bg-primaryLight -z-10" id="testimoni">
            <div className="absolute top-0 left-0 bg-primaryDark w-full h-[252px] -z-10"></div>
            <article className="max-w-container py-16 mx-auto px-6 md:px-4">
            <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                <span className="text-white">Testimoni Pelanggan</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
                <div className="bg-white rounded-[32px] p-6 text-center">
                    <div className="flex justify-center">
                        <img className="rounded-full w-[100] h-[100] mb-2" src={testimoni1} alt="" />
                    </div>
                    <div className="text-base text-dark font-bold">Babe Cabita</div>
                    <div className="text-sm text-default">Comedian</div>
                    <div className="mt-4 text-default">Mantap nih, sofa sedekil apapun jadi kayak baru lagi. Jasa cuci springbed dan sofa paling oke</div>
                </div>
                <div className="bg-white rounded-[32px] p-6 text-center">
                    <div className="flex justify-center">
                        <img className="rounded-full w-[100] h-[100] mb-2" src={testimoni2} alt="" />
                    </div>
                    <div className="text-base text-dark font-bold">Soraya Haque</div>
                    <div className="text-sm text-default">Artis</div>
                    <div className="mt-4 text-default">"Pelayanannya Cleansheet, cepat, bersih, rapih, dan aman. Jasa membersihkan rumah recomended"</div>
                </div>
                <div className="bg-white rounded-[32px] p-6 text-center">
                    <div className="flex justify-center">
                        <img className="rounded-full w-[100] h-[100] mb-2" src={testimoni3} alt="" />
                    </div>
                    <div className="text-base text-dark font-bold">Shahnaz Haque</div>
                    <div className="text-sm text-default">Artist</div>
                    <div className="mt-4 text-default">"Segala permasalahan rumah pokoknya menjadi beres, sampai halaman rumah juga loh. 1 panggilan anda berarti membantu 1 anak putus sekolah. Jasa bersih rumah terpercaya dan mempunyai pelayanan dari hati"</div>
                </div>
            </div>
            </article>
        </section>

    );
}

export default SectionTestimoni;