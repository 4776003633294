import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionOneCorp from "../../../components/LandingPage/ServicesSection/OneCorp/SectionOneCorp";
import SectionLayanan from "../../../components/LandingPage/ServicesSection/OneCorp/SectionLayanan";
import SectionProblems from "../../../components/LandingPage/ServicesSection/OneCorp/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/OneCorp/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/OneCorp/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const OneCorpPage = () => {
    TabTitle("Layanan One Corp Many Hope - Cleansheet");
    return (
        <>
            <SectionVideo />
            <SectionProblems />
            <SectionOneCorp />
            <SectionLayanan />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'One Corp Many Hope'} />
            <FloatingWhatsapp />
        </>
    );
}

export default OneCorpPage;