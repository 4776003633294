import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionGeneralCleaning from "../../../components/LandingPage/ServicesSection/GeneralCleaning/SectionGeneralCleaning";
import SectionLayanan from "../../../components/LandingPage/ServicesSection/GeneralCleaning/SectionLayanan";
import SectionProblems from "../../../components/LandingPage/ServicesSection/GeneralCleaning/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/GeneralCleaning/SectionTestimoni";
import SectionGeneralServices from "../../../components/LandingPage/ServicesSection/GeneralCleaning/SectionGeneralServices";
import TabTitle from "../../../utils/TabTitle";

const GeneralCleaningPage = () => {
    TabTitle("Layanan General Cleaning Profesional - Cleansheet");
    return (
        <>
            <SectionGeneralServices />
            <SectionProblems />
            <SectionGeneralCleaning />
            <SectionLayanan />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'General Cleaning'} />
            <FloatingWhatsapp />
        </>
    );
}

export default GeneralCleaningPage;