import {React} from "react";
import {ReactComponent as Dusting} from '../../../../assets/vectors/icons/dusting.svg';
import {ReactComponent as WindowCleaning} from '../../../../assets/vectors/icons/window-cleaning.svg';
import {ReactComponent as Sweeping} from '../../../../assets/vectors/icons/sweeping.svg';
import {ReactComponent as Spotting} from '../../../../assets/vectors/icons/spotting.svg';

function SectionGeneralCleaning() {
    return (
        <section className="py-16 bg-primaryLight" id="sectionGeneral">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                    Apa Itu <span className="text-primary">General Cleanin?</span>
                </h2>
                <p className='text-base text-default text-[22px] mt-4 mb-10'>Layanan membersihkan rumah, kantor, dan berbagai tempat lainnya secara menyeluruh. Pembersihan dilakukan secara menyeluruh yang menghasilkan ruangan menjadi bersih sempurna, bebas dari debu dan kuman, serta nyaman untuk ditempati.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-14">
                    <div>
                        <div className="px-[72px] py-14 bg-transparent rounded-[32px] border border-primary center-web">
                            <Dusting className="text-primary w-13" />
                        </div>
                        <div className="font-bold mt-4 text-[22px]">Dusting</div>
                        <p className="pt-2 text-default">Proses pembersihan debu pada dinding, langit-langit, dan seluruh furniture dalam ruangan.</p>
                    </div>
                    <div>
                        <div className="px-[72px] py-14 bg-transparent rounded-[32px] border border-primary center-web">
                            <WindowCleaning className="text-primary w-13" />
                        </div>
                        <div className="font-bold mt-4 text-[22px]">Window Cleaning</div>
                        <p className="pt-2 text-default">Proses pembersihan kaca secara menyeluruh hingga kaca akan terlihat jauh lebih bersih dan mengilap.</p>
                    </div>
                    <div>
                        <div className="px-[72px] py-14 bg-transparent rounded-[32px] border border-primary center-web">
                            <Sweeping className="text-primary w-13" />
                        </div>
                        <div className="font-bold mt-4 text-[22px]">Sweeping & Mopping</div>
                        <p className="pt-2 text-default">Proses pembersihan kaca secara menyeluruh hingga kaca akan terlihat jauh lebih bersih dan mengilap.</p>
                    </div>
                    <div>
                        <div className="px-[72px] py-14 bg-transparent rounded-[32px] border border-primary center-web">
                            <Spotting className="text-primary w-13" />
                        </div>
                        <div className="font-bold mt-4 text-[22px]">Spotting</div>
                        <p className="pt-2 text-default">Proses pengecekan dan pembersihan kembali seluruh pembersihan secara detail hingga ke spot-spot terpencil.</p>
                    </div>
                </div>

            </article>
        </section>

    );
}

export default SectionGeneralCleaning;