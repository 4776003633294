import general from "../../../../assets/images/banner/general-service.png";

function SectionProblems() {
  return (
    <section className="py-16" id="sectionGeneralCleaning">
      <article className="relative max-w-container mx-auto px-6 md:px-4">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div className="col-span-1 md:col-span-5 flex justify-center md:block">
            <img src={general} alt="General Cleaning" />
          </div>
          <div className="col-span-1 md:col-span-6 md:col-end-13">
            <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
              Apakah Anda Mengalami{" "}
              <span className="text-primary">Masalah</span> Berikut?
            </h2>
            <ul className="list-disc text-base text-default text-[22px] mt-4 px-6">
              <li>Rumah atau kantor Anda sudah mulai banyak debu</li>
              <li>Keterbatasan tenaga kebersihan</li>
              <li>Tidak ada waktu untuk bersih-bersih</li>
              <li>Tidak memiliki peralatan kebersihan yang memadai</li>
            </ul>
            <p className="text-base text-default text-[22px] mt-4">
              Selamat! Anda berada di halaman yang tepat untuk mendapatkan
              penawaran terbaik jasa membersihkan rumah, kantor, apartemen, dan
              tempat lainnya secara menyeluruh.
            </p>
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionProblems;
