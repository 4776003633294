import model from "../../../assets/images/misc/model.png";
import {mdiArrowRight} from "@mdi/js";
import Icon from "@mdi/react";
import profile from "../../../assets/images/testimoni/profil.png";
import profile2 from "../../../assets/images/testimoni/profil2.png";
import Button from "../../Button";
import {Link} from "react-router-dom";

function SectionBanner() {
  return (
    <section className="bg-banner-texture bg-cover relative" id="beranda">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-6 md:px-4 pt-16 max-w-container mx-auto">
        <article className="flex flex-col gap-6 col-span-1 sm:col-span-6 md:col-span-4 pb-16">
          <div className="montserrat text-dark font-bold text-3xl lg:text-[48px] tracking-[0.005em] leading-[140%] lg:leading-[120%]">
            Jasa Bersih <span className="underlined">Rumah</span> dan{" "}
            <span className="underlined">Kantor</span>
          </div>
          <p className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-dark">
            Menyelesaikan permasalahan kebersihan sekaligus mengurangi anak
            putus sekolah, pengangguran, dan ujungnya Pada Pengentasan
            Kemiskinan. Cleansheet telah memberdayakan 250+ anak muda dan akan
            semakin berkembang. Semakin banyak yang order, semakin banyak yang
            dibantu.{" "}
            <span className="font-bold">#BusinessForHelpingPeople</span>
          </p>
          <div>
            <Button btnType="secondary" customStyle=" rounded-full">
              <div className="px-3 py-1.5 text-dark not-italic font-bold text-base leading-[22px] text-center tracking-[0.005em] whitespace-nowrap">
                Kenapa Harus Kami?
              </div>
            </Button>
          </div>
          <Link to={"/how-to-order"}>
            <div className="flex flex-row gap-1 items-center text-primaryDark not-italic font-normal text-base leading-[22px] tracking-[0.005em]">
              Lihat Cara Pemesanan
              <Icon path={mdiArrowRight} size={0.75} />
            </div>
          </Link>
        </article>
        <div className="col-span-1 md:col-end-13 md:col-span-6 lg:col-span-5 flex items-end">
          <img src={model} alt="Banner Modal" />
        </div>
        <aside className="hidden lg:col-span-3 lg:flex flex-col gap-7 pb-16 ">
          <a href="https://mnews.co.id/read/anak-muda-punya-cerita/inspiratif-pemuda-ini-bantu-mahasiswa-dan-anak-putus-sekolah-melalui-usaha-home-cleaning-service/">
            <div className="bg-white drop-shadow-bottom py-4 px-5 pb-4 rounded-xl flex flex-col gap-1">
              <div className="flex flex-row">
                <div className="flex-auto not-italic font-bold text-sm leading-[140%] tracking-[0.005em] text-primary">
                  Artikel
                </div>
                <div>
                  <Icon
                    path={mdiArrowRight}
                    size={0.75}
                    className="text-primary"
                  />
                </div>
              </div>
              <div className="text-sm !leading-[140%] !tracking-wider font-normal text-dark">
                <span className="font-bold">Inspiratif!</span> Pemuda Ini Bantu
                Mahasiswa Dan Anak Putus Sekolah Melalui Usaha Home & Office
                Cleaning Service
              </div>
            </div>
          </a>
          <div className="pt-8 relative">
            <img
              src={profile}
              alt=""
              className="rounded-full h-16 w-16 border-2 border-white absolute top-0 left-8 z-10"
            />
            <div className="bg-white drop-shadow-bottom px-[20px] pt-[42px] pb-4 rounded-xl">
              <div className="text-sm !leading-[140%] !tracking-wider font-normal text-dark">
                Mantap nih, sofa sedekil apapun jadi kayak baru lagi. Jasa cuci
                springbed dan sofa paling oke
              </div>
              <div className="py-2 text-dark font-bold !leading-[140%] !tracking-wider">
                Soraya Haque - Artis
              </div>
            </div>
          </div>
          <div className="pt-8 relative">
            <img
              src={profile2}
              alt=""
              className="rounded-full h-16 w-16 border-2 border-white absolute top-0 left-8 z-10"
            />
            <div className="bg-white drop-shadow-bottom px-[20px] pt-[42px] pb-4 rounded-xl">
              <div className="text-sm !leading-[140%] !tracking-wider font-normal text-dark">
                Pelayanannya Cleansheet, cepat, bersih, rapih, dan aman. Jasa
                membersihkan rumah recomended
              </div>
              <div className="py-2 text-dark font-bold !leading-[140%] !tracking-wider">
                Babe Cabita - Artis
              </div>
            </div>
          </div>
        </aside>
      </div>
    </section>
  );
}

export default SectionBanner;
