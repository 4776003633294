import React from "react";
import sanusi from "../../../assets/images/testimoni/cerita-1.png";
import fajri from "../../../assets/images/testimoni/cerita-2.png";
import bagus from "../../../assets/images/testimoni/cerita-3.png";

const SectionDampak = () => {
  let dampak = [
    {
      name: "Sanusi",
      position: "Rangers Biru Cleansheet",
      message:
        "Saya sangat senang saat mendapatkan beasiswa untuk melanjutkan pendidikan saya ke jenjang perkuliahan. Terlebih lagi orang tua turut berbahagia anaknya dapat melanjutkan pendidikannya untuk meraih kesuksesan",
      image: sanusi,
    },
    {
      name: "Fajri",
      position: "Rangers Biru Cleansheet",
      message:
        "Senang bisa dapat kesempatan kembali menuntut ilmu karena tidak ada kekayaan seperti pengetahuan, tidak ada kemiskinan seperti ketidaktahuan",
      image: fajri,
    },
    {
      name: "Bagus",
      position: "Alumni Cleansheet",
      message:
        "Cleansheet merupakan salah satu faktor yang berimpact pada karir saya untuk mewujudkan cita-cita saya menjadi PNS",
      image: bagus,
    },
  ];
  return (
    <section id="dampak-cleansheet" className="relative">
      <div className="bg-primaryDark h-1/4 md:h-1/3 absolute top-0 left-0 right-0 -z-10"></div>
      <article className="max-w-container mx-auto px-6 md:px-4 py-20 grid grid-cols-12 gap-4">
        <div className="col-span-12 montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-light">
          Dampak Cleansheet
        </div>
        <div className="col-span-12 lg:col-span-5 flex flex-col pt-6 lg:pt-12">
          <div className="iframe-16-9-ratio rounded-xl">
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/O26lHW93A5Y"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6 lg:col-end-13 flex flex-col gap-4 mt-0 lg:-mt-12">
          {dampak.map((item, index) => (
            <div
              key={"dampak" + index}
              className="flex flex-col gap-4 items-start p-6 bg-white rounded-3xl drop-shadow-bottom"
            >
              <div className="flex flex-row gap-4 items-center p-0">
                <img src={item.image} alt={item.name} className="w-14" />
                <div className="flex flex-col items-start p-0">
                  <div className="not-italic font-bold text-xl leading-[140%] tracking-[0.005em] text-dark">
                    {item.name}
                  </div>
                  <div className="not-italic font-normal text-sm leading-[140%] tracking-[0.005em] text-default">
                    {item.position}
                  </div>
                </div>
              </div>
              <p className="p-0 not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
                {item.message}
              </p>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default SectionDampak;
