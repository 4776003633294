import img from '../../../../assets/images/banner/furniture-service.png';

function SectionProblems() {
    return (
        <section className="py-16" id="sectionProblems">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="center-web">
                        <img src={img} alt="" />
                    </div>
                    <div className='self-center'>
                        <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                            Apakah Anda Mengalami <span className="text-primary">Masalah</span> Berikut?
                        </h2>
                        <ul className='list-disc text-base text-default text-[22px] mt-4 px-6'>
                            <li>Kasur sudah mulai bertungau</li>
                            <li>Sofa sudah mulai banyak debu</li>
                            <li>Karpet mulai berbau tidak sedap</li>
                            <li>Kursi-kursi mulai tidak nyaman ketika di duduki</li>
                        </ul>
                        <p className='text-base text-default text-[22px] mt-4'>Selamat! Anda berada di halaman yang tepat untuk mencari mitra jasa kebersihan untuk segala macam furniture Anda yang mulai tidak nyaman, berbau, berdebu, dan banyak tungau</p>
                    </div>
                </div>

            </article>
        </section>
    );
}

export default SectionProblems;