import ipb from "../../../assets/images/brands/ipb.webp";
import kemendikbud from "../../../assets/images/brands/kemendikbud.webp";
import kemenpora from "../../../assets/images/brands/kemenpora.webp";
import rctiplus from "../../../assets/images/brands/rctiplus.webp";
import sindonews from "../../../assets/images/brands/sindonews.webp";
import tvri from "../../../assets/images/brands/tvri.webp";
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel} from "swiper";
function SectionMedia() {
  return (
    <section id="liputan-media" className="py-16">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="font-bold flex justify-center text-[32px] leading-[140%] tracking-wider montserrat">
          Liputan Media
        </div>
        <div>
          <Swiper
            modules={[Mousewheel]}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 5,
              },
            }}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            className="flex items-center"
          >
            <SwiperSlide>
              <img src={sindonews} alt="" className="h-[5rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={rctiplus} alt="" className="h-[5rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={tvri} alt="" className="h-[5rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={ipb} alt="" className="h-[5rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={kemendikbud} alt="" className="h-[5rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={kemenpora} alt="" className="h-[5rem]" />
            </SwiperSlide>
          </Swiper>
        </div>
      </article>
    </section>
  );
}

export default SectionMedia;
