import React from "react";

function SectionOneCorp() {

    return (
        <section className="py-16 bg-primaryLight" id="sectionToilet">
            {/* <img className='-z-10 absolute top-0 right-0' src={bgtop} alt="" />
            <img className='-z-10 absolute bottom-0 left-0' src={bgbottom} alt="" />
 */}
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                    Apa Itu <span className="text-primary">One Corp Many Hope</span>?
                </h2>
                <p className='text-base text-default text-[22px] mt-4 mb-10'>
                    <div>Kondisi lingkungan kerja merupakan hal yang sangat mempengaruhi produktivitas karyawan. Salah satu cara untuk meningkatkan produktivitas lewat lingkungan kerja adalah dengan menjaga kebersihan lingkungan kerja. Cleansheet hadir untuk memberikan pembersihan kantor dengan tujuan untuk menciptakan lingkungan kerja yang sehat, bersih, dan nyaman sehingga dapat terbebas dari penyakit.</div>
                    <div className="mt-6">Dalam program “One Corp Many Hope” yaitu setiap satu kantor yang dibersihkan oleh Cleansheet, dapat memberikan harapan bagi banyak anak muda untuk melanjutkan pendidikannya. Kami mengajak Anda untuk berkontribusi lewat Outsourcing Cleaning Service Cleansheet.</div>
                    <div className="mt-6">Apa keuntungan menggunakan jasa office cleaning untuk perusahaan anda?</div>
                    <ul className='list-disc text-default ml-6'>
                        <li>Kantor harus menjadi tempat yang teratur, higienis, dan nyaman di mana karyawan dapat berkonsentrasi untuk menyelesaikan pekerjaan tanpa gangguan. Layanan office cleaning dapat meningkatkan kenyamanan di tempat kerja.</li>
                        <li>Kantor yang tidak bersih memberikan cerminan yang buruk pada perusahaan. Hal ini dapat mempengaruhi penilaian klien. Sehingga dengan menjaga kebersihan kantor dapat memberikan penilaian yang positif. Buat kesan pertama yang baik dengan memastikan kantor selalu bersih dan nyaman.</li>
                        <li>Meningkatkan produktivitas. Kantor yang kotor dapat mengganggu konsentrasi karyawan untuk bekerja yang dapat menyebabkan keterlambatan konsentrasi dan penurunan produktivitas. Dengan mempekerjakan office cleaner untuk mengurus tugas pembersihan, Anda akan menghemat waktu dan meningkatkan produktivitas karyawan Anda.</li>
                    </ul>
                    <div className="mt-6">Mengetahui bahwa kebersihan kantor berada di tangan yang tepat, perusahaan tidak perlu lagi khawatir akan memenuhi tugas pembersihan hari ini. Cleansheet memiliki peralatan dan keterampilan yang diperlukan untuk memberikan pembersihan yang detail dan maksimal setiap harinya.</div>
                </p>
            </article>
        </section>

    );
}

export default SectionOneCorp;