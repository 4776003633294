import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionPenyemprotanDisinfektan from "../../../components/LandingPage/ServicesSection/PenyemprotanDisinfektan/SectionPenyemprotanDisinfektan";
import SectionProblems from "../../../components/LandingPage/ServicesSection/PenyemprotanDisinfektan/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/PenyemprotanDisinfektan/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/PenyemprotanDisinfektan/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const PenyemprotanDisinfektanPage = () => {
    TabTitle("Layanan Penyemprotan Disinfektan Profesional - Cleansheet");
    return (
        <>
            <SectionVideo />
            <SectionProblems />
            <SectionPenyemprotanDisinfektan />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'Penyemprotan Desinfektan'} />
            <FloatingWhatsapp />
        </>
    );
}

export default PenyemprotanDisinfektanPage;