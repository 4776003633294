import icon1 from '../../../assets/images/testimoni/review-1.png';
import icon2 from '../../../assets/images/testimoni/review-2.png';
import icon3 from '../../../assets/images/testimoni/review-3.png';
import ellipse from '../../../assets/images/textures/left-ellips.png';

function SectionReview() {
    return (
        <section className="py-16 relative bg-primaryLight -z-10" id="review">
            <img
                src={ellipse}
                alt=""
                className="w-[205.8px] h-[218.94] absolute right-0 bottom-0 flip -z-10"
            />
            <article className='max-w-container mx-auto px-6 md:px-4'>
                <div className="absolute top-0 left-0 bg-primaryDark w-full h-[252px] -z-10"></div>
                <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                    <span className="text-white">Kata Mereka</span>
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
                    <div className="bg-white rounded-[32px] p-6 text-center">
                        <div className="flex justify-center">
                            <img className="rounded-full w-[100] h-[100] mb-2" src={icon1} alt="" />
                        </div>
                        <div className="text-base text-dark font-bold">Arto Biantoro</div>
                        <div className="text-sm text-default">Advisor</div>
                        <div className="mt-4 text-default">“Cleansheet merupakan perusahaan yang tidak hanya berfokus bagaimana untuk mencari profit namun juga berfokus untuk memberikan dampak. Tidak hanya memberikan jasa cleaning, tetapi cleansheet juga berfokus pada visi besarnya yaitu membantu anak-anak muda yang terkendala biaya untuk melanjutkan ke jenjang pendidikan yang lebih tinggi. Kita bisa bergerak bersama, tidak hanya dengan menggunakan jasa kebersihan dari Cleansheet tetapi juga membantu anak-anak indonesia untuk mendapatkan kesempatan bersekolah”</div>
                    </div>
                    <div className="bg-white rounded-[32px] p-6 text-center">
                        <div className="flex justify-center">
                            <img className="rounded-full w-[100] h-[100] mb-2" src={icon2} alt="" />
                        </div>
                        <div className="text-base text-dark font-bold">Shahnaz Haque</div>
                        <div className="text-sm text-default">Brand Ambassador</div>
                        <div className="mt-4 text-default">
                            “Tim Rangers Biru mempunyai attitude yang  angat baik. Tidak hanya itu, hasil mbersihannya pun sangat memuaskan. Tidak hanya mendapatkan layanan kebersihan yang terpercaya dan melebihi dari standar, namun dengan menggunakan jasa dari Cleansheet, kita dapat memberikan satu cercah harapan bagi satu anak muda Indonesia”
                        </div>
                    </div>
                    <div className="bg-white rounded-[32px] p-6 text-center">
                        <div className="flex justify-center">
                            <img className="rounded-full w-[100] h-[100] mb-2" src={icon3} alt="" />
                        </div>
                        <div className="text-base text-dark font-bold">Dihqon Nadaamist</div>
                        <div className="text-sm text-default">Founder</div>
                        <div className="mt-4 text-default">“Bermula dari pengalaman pribadi saya, saya dan cleansheet ingin terus membuka lapangan kerja, memberikan harapan untuk anak-anak muda Indonesia untuk melanjutkan pendidikannya serta mengubah masa depannya menjadi lebih baik lagi. Maka cleansheet hadir untuk menyelesaikan permasalahan kebersihan, mengurangi pengangguran, dan anak putus sekolah serta ujungnya di pengentasan kemiskinan”</div>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default SectionReview;