import React from "react";
import Button from "../../../Button";
import api from "../../../../api";
import {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import ModalAlert from "../../../ModalAlert";

function SectionHydroCleaning() {
  const [dataServices, setDataServices] = useState([]);
  const [modalOpen, setModalOpen] = useState({status: false, type: ""});
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });

  const getStringBetweenBrackets = (string) => {
    let start = string.indexOf("[");
    let end = string.indexOf("]");
    return string.substring(start + 1, end);
  };

  const getStringAfterBrackets = (string) => {
    let start = string.indexOf("] ");
    return string.substring(start + 1);
  };

  function kFormatter(rate) {
    return Math.abs(rate) > 999
      ? Math.sign(rate) * (Math.abs(rate) / 1000).toFixed(1) + "K"
      : Math.sign(rate) * Math.abs(rate);
  }

  const getData = async () => {
    try {
      const {data} = await api.get("/v1/categories/5/services");
      let temp = data.data.items;
      let result = [];
      data.data.items.forEach((item, itemIndex) => {
        if (itemIndex !== 0) {
          if (
            getStringBetweenBrackets(temp[itemIndex].name) ===
            getStringBetweenBrackets(temp[itemIndex - 1].name)
          ) {
            result[result.length - 1].services.push({
              id: item.id,
              name: getStringAfterBrackets(item.name),
              unit: item.unit,
              rate: item.rate,
            });
          } else {
            result.push({
              name: getStringBetweenBrackets(item.name),
              services: [
                {
                  id: item.id,
                  name: getStringAfterBrackets(item.name),
                  unit: item.unit,
                  rate: item.rate,
                },
              ],
            });
          }
        } else {
          result.push({
            name: getStringBetweenBrackets(item.name),
            services: [
              {
                id: item.id,
                name: getStringAfterBrackets(item.name),
                unit: item.unit,
                rate: item.rate,
              },
            ],
          });
        }
      });
      setDataServices(result);
    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage: "Gagal Mendapatkan Data Layanan dari Server",
      });
      setModalOpen({status: true, type: "alert"});
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ModalAlert
        isOpen={modalOpen.status && modalOpen.type === "alert"}
        alertTitle={modalAlert.alertTitle}
        alertMessage={modalAlert.alertMessage}
      />
      <section className="py-16 bg-primaryLight" id="sectionToilet">
        <article className="relative max-w-container mx-auto px-6 md:px-4">
          <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
            Apa Itu <span className="text-primary">Hydro Vaccum?</span>
          </h2>
          <p className="text-base text-default text-[22px] mt-4 mb-10">
            Layanan jasa pembersihan hydro vaccum untuk soft furniture seperti
            kasur, sofa, karpet, dan kursi guna mengangkat debu dan tungau
            secara maksimal sehingga membuat kondisi kembali bersih dan nyaman
            untuk digunakan
          </p>
          <div className="mt-14">
            <div className="flex flex-col gap-9">
              {dataServices?.map((items, itemsIndex) => (
                <React.Fragment key={itemsIndex}>
                  <div className="text-[28px] mb-4 font-bold">
                    Cuci {items.name}
                  </div>
                  <div className="w-full">
                    <Swiper
                      spaceBetween={20}
                      breakpoints={{
                        320: {
                          slidesPerView: 1.1,
                        },
                        768: {
                          slidesPerView: 3.1,
                        },
                        1280: {
                          slidesPerView: 4.1,
                        },
                      }}
                    >
                      {items.services.map((service) => (
                        <React.Fragment key={service.id}>
                          <SwiperSlide>
                            <div className="p-4 bg-transparent rounded-[32px] border border-primary bg-white flex flex-col">
                              <div className="text-base montserrat font-bold">
                                {service.name}
                              </div>
                              <div className="text-center mt-14 mb-14 text-[48px] montserrat text-dark font-bold">
                                <span className="font-bold text-[28px]">
                                  Rp
                                </span>
                                {kFormatter(service.rate)}
                                <span className="font-normal text-[22px]">
                                  /{service.unit}
                                </span>
                              </div>
                              <div className="mt-auto flex justify-center items-end">
                                <Button customStyle="center-web rounded-xl">
                                  <div className="px-2 text-white font-bold">
                                    Pesan Layanan
                                  </div>
                                </Button>
                              </div>
                            </div>
                          </SwiperSlide>
                        </React.Fragment>
                      ))}
                    </Swiper>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default SectionHydroCleaning;
