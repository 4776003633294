import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionToiletCleaning from "../../../components/LandingPage/ServicesSection/ToiletCleaning/SectionToiletCleaning";
import SectionProblems from "../../../components/LandingPage/ServicesSection/ToiletCleaning/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/ToiletCleaning/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/ToiletCleaning/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const ToiletCleaningPage = () => {
    TabTitle("Layanan Toilet Cleaning Profesional - Cleansheet");
    return (
        <>
            <SectionVideo />
            <SectionProblems />
            <SectionToiletCleaning />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'Toilet Cleaning'} />
            <FloatingWhatsapp />
        </>
    );
}

export default ToiletCleaningPage;