import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionOneHome from "../../../components/LandingPage/ServicesSection/OneHome/SectionOneHome";
import SectionProblems from "../../../components/LandingPage/ServicesSection/OneHome/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/OneHome/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/OneHome/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const OneHomePage = () => {
    TabTitle("Layanan One Home One Hope - Cleansheet");
    return (
        <>
            <SectionVideo />
            <SectionProblems />
            <SectionOneHome />
            <SectionTestimoni />
            <SectionKenapa background='bg-white' />
            <SectionJasa title={'One Home One Hope'} />
            <FloatingWhatsapp />
        </>
    );
}

export default OneHomePage;