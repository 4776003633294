import {useEffect, useState} from "react";
import model from "../../../assets/images/misc/model.png";
import vectror3 from "../../../assets/images/textures/vector3.png";
function SectionSubscribe() {
  const [emailUser, setEmailUser] = useState("");
  useEffect(() => {
    setEmailUser("");
  }, []);

  return (
    <section id="ikuti-kami" className="py-16">
      <div className="max-w-container mx-auto px-6 md:px-4 flex flex-col">
        <article className="relative overflow-hidden">
          <div className="flex mt-[11px] text-[22px] font-normal leading-[140%] tracking-wider">
            IKUTI KAMI
          </div>
          <div className="mt-[16px] font-bold  flex  text-[32px] leading-[140%] tracking-wider montserrat">
            Berlangganan berita update kami
          </div>
          <div className="bg-primary  xl:h-[346px] rounded-[32px] mt-8 relative px-[58px] py-[53px] ">
            <div className="text-white leading-[140%] tracking-wider text-[20px] z-20 mr-0 2xl:mr-[474px]">
              Ikuti berita terupdate dari kami dengan memasukkan email Anda pada
              kolom yang telah kami sediakan. Kami menyarankan Anda untuk
              membaca syarat dan ketentuang yang berlaku pada webkami melalui{" "}
              <a href="http://" className="font-bold">
                link ini
              </a>
            </div>
            <div className="mt-[35px]">
              <div className="xl:w-[570px] xl:h-[56px] py-6 sm:py-0 rounded-[28px] flex flex-col gap-4 md:flex-row relative">
                <input
                  type="text"
                  placeholder="Alamat Email Anda"
                  className="border-0 text-white placeholder-white inp bg-black/20 w-full pl-6 pr-6 py-[17px] md:pr-44 sm:border-transparent rounded-[28px]"
                />
                <button className="min-w-full md:min-w-fit text-center text-dark bg-white text-base px-6 py-3.5 rounded-[32px] font-bold relative md:absolute md:right-0.5 md:top-0.5">
                  Berlangganan
                </button>
              </div>
            </div>
            <img
              src={model}
              alt=""
              className="h-[474px] absolute bottom-0 right-6 hidden 2xl:block z-10"
            />
            <img
              src={vectror3}
              alt=""
              className=" h-[389px] absolute -bottom-52 -left-40 rotate-180 -z-20 hidden xl:block"
            />
          </div>
        </article>
        <aside className="w-full flex justify-center 2xl:hidden">
          <img src={model} alt="" className="h-[474px] z-10" />
        </aside>
      </div>
    </section>
  );
}

export default SectionSubscribe;
