import vector from "../../../assets/images/misc/Vector.png";
import pesan1 from "../../../assets/images/illustrations/pesan1.png";
import pesan2 from "../../../assets/images/illustrations/pesan2.png";
import pesan3 from "../../../assets/images/illustrations/pesan3.png";
import pesan4 from "../../../assets/images/illustrations/pesan4.png";
import {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {NavLink} from "react-router-dom";
import "swiper/css";
import {Navigation} from "swiper";
import Button from "../../Button";
function SectionPesan() {
  const [pesanActive, setPesanActive] = useState(1);
  const [swiper, setSwiper] = useState();
  const [onMobile, setOnMobile] = useState(false);
  const [onDesktop, setOnDesktop] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth < 768) {
      setOnMobile(true);
    } else {
      setOnMobile(false);
    }
    if (window.innerWidth >= 1280) {
      setOnDesktop(true);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);
  useEffect(() => {
    handleWindowSizeChange();
  }, []);
  const langkah = [
    {
      title: "Langkah 1: Masuk ke Halaman Pemesanan",
      desc: "Download aplikasi Cleansheet melalui Playstore. Kemudian pilih paket jasa yang kamu butuhkan.",
    },
    {
      title: "Langkah 2: Isi Formulir",
      desc: "Isi data kalian dengan lengkap dan jelas agas Customer Service kami dapat memahami tentang permohonan jasa serta data lokasi detail yang telah anda inputkan melalui formulir yang sudah kami sediakan.",
    },
    {
      title: "Langkah 3: Konfirmasi Pesanan",
      desc: "Cek kembali jasa yang kamu pesan serta data lokasi yang akan dibersihkan. Kemudian lakukan pembayaran.",
    },
    {
      title: "Langkah 4: Mulai Bekerja",
      desc: "Tim Cleansheet akan menuju lokasi yang telah kamu inputkan dan langsung melakukan pembersihan sesuai jasa yang kamu  pesan.",
    },
  ];

  return (
    <section id="cara-pesan" className="py-16">
      <article className="max-w-container mx-auto">
        <div className="flex justify-start md:justify-center mt-[11px] text-lg md:text-[22px] font-normal leading-[140%] tracking-wider px-6 md:px-4">
          CARA PESAN
        </div>
        <div className="mt-[16px] font-bold text-2xl flex justify-start md:justify-center md:text-[32px] leading-[140%] tracking-wider montserrat px-6 md:px-4">
          Ikuti 4 langkah mudah memesan jasa kami
        </div>
        <div className="flex justify-center px-6 md:px-4">
          <img
            src={vector}
            alt="vector"
            className=" w-[189px] h-1   mt-[31px] mb-[54px]"
          />
        </div>

        <div className="min-w-0 px-0 xl:px-4">
          <Swiper
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            navigation={true}
            onSlideChange={(index) => {
              setPesanActive(index.activeIndex + 1);
            }}
            modules={[Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 1.1,
                centeredSlides: true,
              },
              768: {
                slidesPerView: 2.1,
                centeredSlides: false,
              },
              1024: {
                slidesPerView: 3.1,
                centeredSlides: false,
              },
              1280: {
                slidesPerView: 4,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              {({isActive}) => (
                <div className="flex flex-col p-0">
                  <button
                    className="p-5 h-[250px]"
                    onClick={() => {
                      setPesanActive(1);
                      swiper.slideTo(0);
                    }}
                  >
                    <div className="cardTestimoni h-[155px] p-3 flex justify-center">
                      <img src={pesan1} alt="" className="h-full" />
                    </div>
                  </button>
                  <div className="w-full relative my-5">
                    <div className="h-0.5 w-1/2 ml-auto bg-bright"></div>
                    <div className="absolute -top-4 left-0 right-0 bottom-0 w-full flex justify-center">
                      <button
                        disabled={isActive}
                        onClick={() => {
                          setPesanActive(1);
                          swiper.slideTo(0);
                        }}
                        className={`${
                          pesanActive === 1
                            ? "bg-primaryDark text-white "
                            : "bg-white text-dark "
                        }w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center`}
                      >
                        1
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({isActive}) => (
                <div className="flex flex-col p-0">
                  <button
                    className="p-5 h-[250px]"
                    onClick={() => {
                      setPesanActive(2);
                      swiper.slideTo(1);
                    }}
                  >
                    <div className="cardTestimoni h-[155px] p-3 flex justify-center">
                      <img src={pesan2} alt="" className="h-full" />
                    </div>
                  </button>
                  <div className="w-full relative my-5">
                    <div className="h-0.5 bg-bright"></div>
                    <div className="absolute -top-4 left-0 right-0 bottom-0 w-full flex justify-center">
                      <button
                        disabled={isActive}
                        onClick={() => {
                          setPesanActive(2);
                          swiper.slideTo(1);
                        }}
                        className={`${
                          pesanActive === 2
                            ? "bg-primaryDark text-white "
                            : "bg-white text-dark "
                        }w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center`}
                      >
                        2
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({isActive}) => (
                <div className="flex flex-col p-0">
                  <button
                    className="p-5 h-[250px]"
                    onClick={() => {
                      setPesanActive(3);
                      swiper.slideTo(2);
                    }}
                  >
                    <div className="cardTestimoni h-[155px] p-3 flex justify-center">
                      <img src={pesan3} alt="" className="h-full" />
                    </div>
                  </button>
                  <div className="w-full relative my-5">
                    <div className="h-0.5 bg-bright"></div>
                    <div className="absolute -top-4 left-0 right-0 bottom-0 w-full flex justify-center">
                      <button
                        disabled={isActive}
                        onClick={() => {
                          setPesanActive(3);
                          swiper.slideTo(2);
                        }}
                        className={`${
                          pesanActive === 3
                            ? "bg-primaryDark text-white "
                            : "bg-white text-dark "
                        }w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center`}
                      >
                        3
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({isActive}) => (
                <div className="flex flex-col p-0">
                  <button
                    className="p-5 h-[250px]"
                    onClick={() => {
                      setPesanActive(4);
                      swiper.slideTo(3);
                    }}
                  >
                    <div className="cardTestimoni h-[155px] p-3 flex justify-center">
                      <img src={pesan4} alt="" className="h-full" />
                    </div>
                  </button>
                  <div className="w-full relative my-5">
                    <div className="h-0.5 w-1/2 mr-auto bg-bright"></div>
                    <div className="absolute -top-4 left-0 right-0 bottom-0 w-full flex justify-center">
                      <button
                        disabled={isActive}
                        onClick={() => {
                          setPesanActive(4);
                          swiper.slideTo(3);
                        }}
                        className={`${
                          pesanActive === 4
                            ? "bg-primaryDark text-white "
                            : "bg-white text-dark "
                        }w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center`}
                      >
                        4
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
            {!onMobile && !onDesktop && (
              <SwiperSlide>
                {({isActive}) => (
                  <div className="flex flex-col p-0">
                    <button className="p-5 h-[250px]">
                      <div className="h-[155px] p-3 flex justify-center"></div>
                    </button>
                    <div className="w-full relative my-5"></div>
                  </div>
                )}
              </SwiperSlide>
            )}
          </Swiper>
        </div>

        <div className="flex justify-center mt-[62px] px-6 md:px-4">
          <div>
            <div className="font-bold flex justify-start md:justify-center sm:text-[24px] leading-[140%] tracking-wider montserrat">
              {langkah[pesanActive - 1].title}
            </div>
            <div className="hidden md:flex justify-center">
              <img src={vector} alt="vector" className="w-[189px] h-1 my-6" />
            </div>
            <div className="flex justify-start md:justify-center text-left md:text-center sm:text-[20px] leading-[140%] tracking-wider ">
              {langkah[pesanActive - 1].desc}
            </div>
            <div className="flex justify-center mt-6">
              <NavLink to="/form" className="flex self-center justify-center">
                <Button customStyle=" rounded-full">
                  <div className="montserrat not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-white px-5 py-1">
                    Hubungi
                  </div>
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionPesan;
