import React from "react";
import Button from "../../../Button";

function SectionOneHome() {
  let layanan = [
    {
      kind: "service",
      id: "8",
      name: "Paket 1",
      unit: "bln",
      rate: 499000,
      benefit1: "Jadwal 1x per minggu",
      benefit2: "2 jam perkedatangan",
      benefit3: "Berlangganan 1 TAHUN, Bonus 1 jam pengerjaan",
    },
    {
      kind: "service",
      id: "8",
      name: "Paket 2",
      unit: "bln",
      rate: 799000,
      benefit1: "Jadwal 1x per minggu",
      benefit2: "3 jam perkedatangan",
      benefit3: "Bonus 1 jam pengerjaan",
    },
    {
      kind: "service",
      id: "8",
      name: "Paket 3",
      unit: "bln",
      rate: 1199000,
      benefit1: "Jadwal 2x per minggu",
      benefit2: "3 jam perkedatangan",
      benefit3: "Bonus 1 jam pengerjaan dan fogging disinfektan",
    },
    {
      kind: "service",
      id: "8",
      name: "Paket 4",
      unit: "bln",
      rate: 1699000,
      benefit1: "Jadwal 3x per minggu",
      benefit2: "3 jam perkedatangan",
      benefit3: "Bonus 2 jam pengerjaan dan fogging disinfektan",
    },
  ];

  function kFormatter(rate) {
    return Math.abs(rate) > 999
      ? Math.sign(rate) * (Math.abs(rate) / 1000).toFixed(1) + "K"
      : Math.sign(rate) * Math.abs(rate);
  }

  return (
    <section className="py-16 bg-primaryLight" id="sectionToilet">
      <article className="relative max-w-container mx-auto px-6 md:px-4">
        <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
          Apa Itu <span className="text-primary">One Home One Hope</span>?
        </h2>
        <p className="text-base text-default text-[22px] mt-4 mb-10">
          <div>
            Dalam program “One Home One Hope” yaitu setiap satu rumah yang
            dibersihkan oleh Cleansheet, dapat memberikan harapan bagi satu anak
            muda untuk melanjutkan pendidikannya. Kami mengajak sobat Cleansheet
            untuk berkontribusi lewat paket berlangganan bulanan dari
            Cleansheet.
          </div>
          <div className="mt-6">
            Dengan Paket Berlangganan Bulanan “One Home One Hope” masalah anda
            akan terselesaikan. Layanan yang diberikan antara lain:
          </div>
          <ul className="list-disc text-default ml-6">
            <li>
              Layanan pembersihan General Cleaning yang meliputi pembersihan
              debu (dusting), pembersihan seluruh permukaan furniture dalam
              ruangan.
            </li>
            <li>
              Melakukan pekerjaan rumah lainnya (setrika, mencuci piring, dan
              lain-lain)
            </li>
          </ul>
        </p>
        <div className="mt-14">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9">
            {layanan.map((items, itemsIndex) => (
              <React.Fragment key={itemsIndex}>
                {itemsIndex === 0 && (
                  <div className="text-[28px] mb-4 font-bold col-span-1 md:col-span-2 lg:col-span-4">
                    Daftar Harga
                  </div>
                )}
                <div className="p-4 bg-transparent rounded-[32px] border border-primary bg-white flex flex-col">
                  <div className="text-base montserrat font-bold">
                    {items.name}
                  </div>
                  <div className="text-center mt-6 text-[48px] montserrat text-dark font-bold">
                    <span className="font-bold text-[28px]">Rp</span>
                    {kFormatter(items.rate)}
                    <span className="font-normal text-[22px]">
                      /{items.unit}
                    </span>
                  </div>
                  <ul className="list-disc text-default mt-2 mb-6 text-sm ml-6">
                    <li>{items.benefit1}</li>
                    <li>{items.benefit2}</li>
                    <li>{items.benefit3}</li>
                  </ul>
                  <div className="mt-auto flex justify-center items-end">
                    <Button customStyle="center-web rounded-xl">
                      <div className="px-2 text-white font-bold">
                        Pesan Layanan
                      </div>
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionOneHome;
